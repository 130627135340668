nav.navbar {
  position: relative;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 30px 20px;

  svg {
    height: 35px;
    width: auto;
  }

  &.navbar-expand > .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  &.header {
    overflow: visible !important;

    .avatar-dropdown > a {
      padding: 0;

      &:after {
        content: none;
      }

      .username {
        margin-right: 0.5rem;
      }

      .avatar {
        height: 35px;
        border-radius: 9999px;
      }
    }
  }

  &.issue-overview {
    border-top: 1px solid rgba(149, 157, 165, 0.2);
  }
}

.navbar-expand-lg > .container {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
