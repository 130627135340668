.selected-user {
  width: 100%;
  padding: 0 15px;
  min-height: 64px;
  line-height: 64px;
  border-bottom: 1px solid #e6ecf3;
  -webkit-border-radius: 0 3px 0 0;
  -moz-border-radius: 0 3px 0 0;
  border-radius: 0 3px 0 0;

  span {
    line-height: 100%;

    &.name {
      font-weight: 700;
    }
  }
}
.chat-container {
  position: relative;

  li {
    &.chat-left,
    &.chat-right {
      display: flex;
      flex: 1;
      flex-direction: row;
      margin-bottom: 40px;
    }

    img {
      width: 48px;
      height: 48px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      border-radius: 30px;
    }

    .chat-avatar {
      margin-right: 20px;
    }

    .chat-name {
      font-size: 0.75rem;
      color: #999999;
      text-align: center;
    }

    .chat-text {
      padding: 0.4rem 1rem;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      background: #f4f5fa;
      font-weight: 300;
      line-height: 150%;
      min-width: 100px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        top: 10px;
        left: -20px;
        border: 10px solid;
        border-color: transparent #f4f5fa transparent transparent;
      }
    }

    .chat-hour {
      padding: 0;
      margin-bottom: 10px;
      font-size: 0.75rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 0 0 0 15px;

      > span {
        font-size: 16px;
        color: #9ec94a;
      }
    }

    &.chat-right {
      justify-content: flex-end;

      > .chat-avatar {
        margin-left: 20px;
        margin-right: 0;
      }

      > .chat-text {
        background: #29c47d;
        color: #fff;

        &:before {
          right: -20px;
          border-color: transparent transparent transparent #29c47d;
          left: inherit;
        }
      }

      > .chat-hour {
        margin: 0 15px 0 0;
      }
    }
  }
}

@media (max-width: 767px) {
  .chat-container {
    li {
      &.chat-left,
      &.chat-right {
        flex-direction: column;
        margin-bottom: 30px;
      }

      img {
        width: 32px;
        height: 32px;
      }

      &.chat-left {
        .chat-avatar {
          margin: 0 0 5px 0;
          display: flex;
          align-items: center;
        }

        .chat-hour {
          justify-content: flex-end;
        }

        .chat-name {
          margin-left: 5px;
        }
      }

      &.chat-right {
        .chat-avatar {
          order: -1;
          margin: 0 0 5px 0;
          align-items: center;
          display: flex;
          justify-content: right;
          flex-direction: row-reverse;
        }

        .chat-hour {
          justify-content: flex-start;
          order: 2;
        }

        .chat-name {
          margin-right: 5px;
        }

        .chat-text {
          font-size: 0.8rem;
        }
      }
    }
  }
}

.chat-form {
  padding: 15px;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  border-top: 1px solid white;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.card {
  border: 0;
  background: #f4f5fb;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  margin-bottom: 2rem;
  box-shadow: none;
}

.chat-text-box {
  border-top: 1px solid rgba(149, 157, 165, 0.2);
}
