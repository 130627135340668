.protected-content {
  background: #e4e6eb;
  transition: opacity 1s;
  opacity: 0;
  height: 100vh;

  &.loading {
    z-index: -1;

    * {
      z-index: -1;
    }
  }

  &.loaded {
    opacity: 1;
  }

  > * {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.loading-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #29c47d;

  &.loaded {
    z-index: -999;
  }

  .loading-content {
    margin-top: 50vh;
    transform: translateY(-50%);

    .brand {
      text-align: center;

      svg {
        width: 300px;
        max-width: 80%;
      }
    }

    .spinner {
      width: 60px;
      height: 60px;
      margin: 0 auto 40px;

      position: relative;
    }

    .double-bounce1,
    .double-bounce2 {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #ffffff;
      opacity: 0.6;
      position: absolute;
      top: 0;
      left: 0;

      -webkit-animation: sk-bounce 2s infinite ease-in-out;
      animation: sk-bounce 2s infinite ease-in-out;
    }

    .double-bounce2 {
      -webkit-animation-delay: -1s;
      animation-delay: -1s;
    }

    @-webkit-keyframes sk-bounce {
      0%,
      100% {
        -webkit-transform: scale(0);
      }
      50% {
        -webkit-transform: scale(1);
      }
    }

    @keyframes sk-bounce {
      0%,
      100% {
        transform: scale(0);
        -webkit-transform: scale(0);
      }
      50% {
        transform: scale(1);
        -webkit-transform: scale(1);
      }
    }
  }
}
