body,
#root {
  overflow-x: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.list-group-item {
  position: static;
}

.form-control,
.custom-select,
.btn {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 2px 15px;
  transition: box-shadow 0.5s;

  &.form-control,
  &.custom-select {
    border-color: #ced4da !important;
  }
}

.form-control:focus,
.custom-select:focus,
.btn:focus,
.btn:active {
  box-shadow: rgba(149, 157, 165, 0.3) 0px 5px 15px !important;
}
